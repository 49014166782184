// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_API_URL: 'https://localhost:7132',
  API_URL: 'https://dev-orderadmin-api.jamatu.com/api/v1',
  LOGOUT_REDIRECT_URL: 'https://dev.jamatu.com/',
  ORDER_ADMIN_URL: 'https://dev-orderadmin.jamatu.com/',
  ADS_MANAGER_URL: 'https://dev-adsmanager.jamatu.com/',
  ORDER_APP_URL: 'https://dev-orderapp.jamatu.com/',
  QRCODE_URL: 'https://dev-orderadmin-service.jamatu.com/api',
  AUTH0_DOMAIN: 'dev-mexdubsh7cvlq1b8.us.auth0.com',
  AUTH0_CLIENTID: 'hkTekrwbGkV50iXUd3TNw0ISLr1Dn0qN',
  AUTH0_AUDIENCE: 'https://dev-orderadmin-api.jamatu.com/',
  AUTH0_API_PATH: '/api/v2/',
  AUTH0_PRE_PATH: 'dev-',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
