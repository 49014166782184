import { Component, OnInit } from '@angular/core';
import { NavItem, RoleFacade } from '@adminpanels/shared-ui';
import { NAV_ITEMS } from './app.data';
import { TranslateService } from '@ngx-translate/core';
import { fullSessionTracker } from 'fullsession';

@Component({
  selector: 'adsmanager-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public readonly NAV_ITEMS: NavItem[] = NAV_ITEMS;

  constructor(
    private readonly _translate: TranslateService,
    private readonly _roleFacade: RoleFacade,
  ) {
    // this._roleFacade.setRoles(this._keycloakService.getUserRoles(true));
    // this._translate.addLangs(['en', 'de']);
    // this._translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    fullSessionTracker.initialize('cus_m3irwwbhjik');
  }
}
