import { NavItem } from '@adminpanels/shared-ui';

export const NAV_ITEMS: NavItem[] = [
  {
    text: 'Dashboard',
    link: 'dashboard',
    iconClass: 'fa-duotone fa-objects-column py-1',
  },
  {
    text: 'Kunden',
    link: 'customers',
    iconClass: 'fa-duotone fa-briefcase py-1',
  },
];
