import { Injectable } from '@angular/core';
import BusinessService from './business.service';
import { finalize, Observable, take } from 'rxjs';
import {
  BusinessSubcategory,
  BusinessType,
  SnackbarService,
  StateData,
} from '@adminpanels/shared-ui';
import { Router } from '@angular/router';
import {
  Country,
  CreateBusinessRequestModel,
  GetProductsForBusinessByCategoriesResponse,
} from '@adminpanels/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
class BusinessState {
  private readonly _countries = new StateData<Country[]>([]);
  private readonly _businessTypes = new StateData<BusinessType[]>([]);
  private readonly _menuProducts =
    new StateData<GetProductsForBusinessByCategoriesResponse>([]);
  private readonly _businessId = new StateData<number | null>(null);
  private readonly _userId = new StateData<string | null>(null);
  private readonly _emailVerified = new StateData<boolean>(false);
  private readonly _businessSubcategories = new StateData<
    Map<number, BusinessSubcategory[]>
  >(new Map());
  public countries$ = this._countries.asObservable();
  public readonly menuProducts$ = this._menuProducts.asObservable();
  public businessTypes$ = this._businessTypes.asObservable();
  public businessId$ = this._businessId.asObservable();
  public userId$ = this._userId.asObservable();
  public emailVerified$ = this._emailVerified.asObservable();

  constructor(
    private readonly _businessService: BusinessService,
    private readonly _router: Router,
    private readonly _snackbarService: SnackbarService,
  ) {}

  private readonly businessIdStore = 'businessIdStore';
  private readonly userIdStore = 'userIdStore';
  private readonly emailVerified = 'emailVerified';

  public refreshStore(): void {
    this._businessId.data.next(
      Number(localStorage.getItem(this.businessIdStore)),
    );
    this._userId.data.next(localStorage.getItem(this.userIdStore));
  }

  public getCountryById(id: number): Country | undefined {
    return (
      this._countries.data
        .getValue()
        .find((country) => country.countryId === id) ?? undefined
    );
  }

  public setEmailVerified(isVerified: boolean) {
    this._emailVerified.data.next(isVerified);
    localStorage.setItem(this.emailVerified, String(isVerified));
  }

  public setUserId(userId: string) {
    this._userId.data.next(userId);
    localStorage.setItem(this.userIdStore, String(userId));
  }

  public setBusinessId(businessId: number) {
    this._businessId.data.next(businessId);
    localStorage.setItem(this.businessIdStore, String(businessId));
  }

  public getCurrentUserId(): string | null {
    return this._userId.data.getValue();
  }

  public checkIsVerified(): boolean {
    return this._emailVerified.data.getValue();
  }

  public getCurrentBusinessId(): number | null {
    return this._businessId.data.getValue();
  }

  public clearUserAndBusinessId(): void {
    // Clear the values from the StateData instances
    this._userId.data.next(null);
    this._businessId.data.next(null);

    // Remove the items from localStorage
    localStorage.removeItem(this.userIdStore);
    localStorage.removeItem(this.businessIdStore);
  }

  public getCountries(): void {
    this._countries.isLoading.next(true);

    this._businessService
      .getCountries()
      .pipe(
        take(1),
        finalize(() => {
          this._countries.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (countries) => {
          this._countries.data.next(countries);
        },
      });
  }

  public getBusinessTypes(): void {
    this._businessTypes.isLoading.next(true);
    this._businessSubcategories.isLoading.next(true);

    this._businessService
      .getBusinessTypes()
      .pipe(
        take(1),
        finalize(() => {
          this._businessTypes.isLoading.next(false);
          this._businessSubcategories.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (businessTypes) => {
          const businessSubcategories: Map<number, BusinessSubcategory[]> =
            new Map();
          for (const businessType of businessTypes) {
            const { subCategories, businessTypeId } = businessType;
            businessSubcategories.set(businessTypeId, subCategories);
          }

          this._businessTypes.data.next(businessTypes);
          this._businessSubcategories.data.next(businessSubcategories);
        },
      });
  }

  public fetchMenuProducts(
    businessId: number,
    menuId: number,
  ): Observable<GetProductsForBusinessByCategoriesResponse> {
    this._menuProducts.isLoading.next(true);
    this._menuProducts.error.next(false);

    return this._businessService
      .fetchMenuProducts({
        businessId: businessId,
        menuId: menuId,
      })
      .pipe(
        take(1),
        finalize(() => {
          this._menuProducts.isLoading.next(false);
        }),
      );
  }

  public getSubcategories(
    businessTypeId: number,
  ): BusinessSubcategory[] | undefined {
    return businessTypeId
      ? this._businessSubcategories.data.getValue().get(businessTypeId)
      : undefined;
  }

  public createBusiness(params: CreateBusinessRequestModel): void {
    this._businessId.isLoading.next(true);
    this._businessService
      .createBusiness(params)
      .pipe(
        take(1),
        finalize(() => {
          this._businessId.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (businessId: number) => {
          this.setBusinessId(businessId);
          this._snackbarService.trigger(
            `${params.businessName} erfolgreich erstellt!`,
          );
          this._router.navigate(['/overview']).then();
        },
      });
  }
}

export default BusinessState;
