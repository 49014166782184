export const environment = {
    production: false,
    API_URL: 'https://localhost:7282/api/v1',
    BASE_API_URL: 'https://dev-orderadmin-service.jamatu.com',
    ORDER_ADMIN_URL: 'https://dev-orderadmin.jamatu.com/',
    ADS_MANAGER_URL: 'https://dev-adsmanager.jamatu.com/',
    AUTH0_DOMAIN: 'dev-mexdubsh7cvlq1b8.us.auth0.com',
    AUTH0_AUDIENCE: 'https://dev-orderadmin-api.jamatu.com/',
    AUTH0_CLIENTID: 'hkTekrwbGkV50iXUd3TNw0ISLr1Dn0qN',
    AUTH0_API_PATH: '/api/v2/',
    AUTH0_PRE_PATH: 'dev-',
};
